export enum STATUS_COLORS {
  DESATIVADO = "#9AA8B3",
  EXECUTADO = "#2EC4B6",
  EM_EXECUCAO = "#1C86FF",
  A_INICIAR = "#7956F7",
  ATRASADO_A_INICIAR = "#FB7756",
  ATRASADO_A_TERMINAR = "#E71D36",
  NO_PRAZO = "#2EC4B6",
  FORA_DO_PRAZO = "#E71D36",
}
