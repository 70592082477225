import { extendTheme } from "native-base";

const theme = extendTheme({
  colors: {
    primary: {
      ligther: "#DDD5FD",
      ligth: "#9B80F9",
      medium: "#7956F7",
      dark: "#3D2B7C",
      darker: "#1E163E",
    },
    neutral: {
      ligther: "#FFFFFF",
      ligth: "#EFF0F1",
      medium: "#D6D7DA",
      dark: "#747880",
      darker: "#000000",
    },
    success: {
      ligther: "#C4F2D1",
      ligth: "#4ED874",
      medium: "#13CB46",
      dark: "#0A6623",
      darker: "#053312",
    },
    error: {
      ligther: "#F2C7C4",
      ligth: "#D8564E",
      medium: "#CB1E13",
      dark: "#660F0A",
      darker: "#330805",
    },
    info: {
      ligther: "#C4D9F2",
      ligth: "#4E8DD8",
      medium: "#1367CB",
      dark: "#0A3466",
      darker: "#051A33",
    },
  },
//   fontSizes: {

//   },
  config: {
    initialColorMode: "light",
  },
});

const colors = {
  primary: {
    ligther: "#DDD5FD",
    ligth: "#9B80F9",
    medium: "#7956F7",
    dark: "#3D2B7C",
    darker: "#1E163E",
  },
  neutral: {
    ligther: "#FFFFFF",
    ligth: "#EFF0F1",
    medium: "#D6D7DA",
    dark: "#646170",
    darker: "#000000",
  },
  success: {
    ligther: "#C4F2D1",
    ligth: "#4ED874",
    medium: "#13CB46",
    dark: "#0A6623",
    darker: "#053312",
  },
  error: {
    ligther: "#F2C7C4",
    ligth: "#D8564E",
    medium: "#CB1E13",
    dark: "#660F0A",
    darker: "#330805",
  },
  info: {
    ligther: "#C4D9F2",
    ligth: "#4E8DD8",
    medium: "#1367CB",
    dark: "#0A3466",
    darker: "#051A33",
  },
}

export { theme, colors };
